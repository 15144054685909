import React from 'react';
import '../style/section.css';

const Section = ({ children, wrapper = true, backgroundColor = 'transparent',backgroundImage='none' }) => {
    return (
        <section className="section" style={{ backgroundColor, backgroundImage }}>
            {wrapper ? <div className="section-wrapper">{children}</div> : children}
        </section>
    );
};

export default Section;