const researchFile = {
    "research":[
        {
            "title":"Researcher (Combinatorics and optimization)",
            "name": "Western University",
            "time": "September 2022 — Present",
            "src":"COP.png",
            "desc":[
                "Spearheaded a groundbreaking research project focused on addressing combinatorial optimization challenges through the innovative application of graph-based neural networks, pushing the boundaries of traditional computational methods.",
                "Conducted extensive research into advanced algorithms and methodologies for solving NP-complete and NP-hard problems within graph-based frameworks, contributing to the field of computational complexity theory.",
                "Pioneered the integration of quantum annealing techniques, Hamiltonian Ising models, and Quadratic Unconstrained Binary Optimization (QUBO) matrices, aiming to revolutionize the efficiency and accuracy of solutions in graph-based neural networks.",
                "Developed a novel physics-informed approach to optimize graph convolutional networks, targeting the resolution of NP-hard problems with potential applications in a range of complex systems and industries.",
                "Contributed to the interdisciplinary dialogue between physics, computer science, and applied mathematics, setting new benchmarks in the pursuit of solving some of the most challenging problems in optimization and network theory."
            ]
        },
        {
            "title": "Directed Research Project",
            "name": "Western University",
            "time": "September 2023 — Present",
            "src":"DRP.png",
            "desc": [
                "Spearheaded a Directed Research Project, leading a team of undergraduate students in exploring advanced concepts in parallel computing, including MIMD (Multiple Instruction, Multiple Data) and SIMD (Single Instruction, Multiple Data) architectures.",
                "Expertly guided research in the application of MPI (Message Passing Interface) and CUDA (Compute Unified Device Architecture), demonstrating a profound understanding of high-performance computing paradigms and their practical applications.",
                "Orchestrated the project's research agenda, aligning investigations with cutting-edge developments in parallelization techniques and technologies.",
                "Mentored undergraduate students in both theoretical and practical aspects of parallel computing, fostering a deep understanding of complex concepts and encouraging hands-on experience with contemporary tools and frameworks.",
                "Led the design and implementation of experimental setups utilizing CUDA, showcasing innovative approaches to leveraging GPU architectures for computational efficiency and problem-solving in complex scenarios.",
                ]
        },
        {
            "title": "Software Infrastructure Lead",
            "name": "UTHT - University of Toronto Hyperloop Team",
            "time": "July 2020 - June 2022",
            "src": "utht.png",
            "desc": [
                "Led and mentored a diverse team of developers in the research, design, and development phases of these complex technical projects, fostering a culture of innovation and excellence within UTHT.",
                "Spearheaded the design and implementation of a real-time dashboard using AngularJS and other advanced frameworks, enhancing the visualization and monitoring of critical Hyperloop pod metrics such as speed and brake pressure",
                "Pioneered the development of a bespoke middleware library, leveraging WebSocket technology for seamless and instantaneous data transfer between the Hyperloop pod hardware and the AngularJS dashboard.",
                "Authored and deployed a specialized C++ library for the Odroid platform, integral for real-time operational management of the Hyperloop pod, ensuring efficient interfacing with all on-board hardware systems.",
                "Conceptualized and executed a sophisticated simulation environment, replicating the Hyperloop pod's functionality, enabling rigorous pre-deployment testing and status monitoring through the dashboard.",
                "Created and maintained Software Architecture Documentation (SAD) and Software Design Documentation (SDD)."
            ]
        },
        {
            "title": "Software Lead",
            "name": "IAI Lab - Intelligent Adaptive Interventions Lab",
            "time": "July 2020 - June 2022",
            "src": "IAI.png",
            "desc": [
                "Played a pivotal role as Lead Software Developer in advancing the IAI Lab's mission of transforming user interfaces into intelligent, adaptive systems, significantly contributing to the enhancement and personalization of user interventions.",
                "Led the software development lifecycle of MOOCLet software, implementing cutting-edge features and functionalities that align with the lab's vision of creating dynamic, user-centric applications.",
                "Championed Continuous Integration/Continuous Deployment (CI/CD) practices, ensuring streamlined, efficient, and error-free deployment of MOOCLet software, enhancing the lab's capacity for rapid iteration and responsiveness to user needs.",
                "Actively contributed to research and development discussions, bringing a software-centric perspective to the interdisciplinary team, aiding in the holistic development of intelligent, adaptive interventions."
            ]
        }
    ]
}

export default researchFile