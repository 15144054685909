import React from 'react';
import '../style/career.css'
import {
    Lettering,
} from '../Home/Home'
// import 'react-awesome-button/dist/themes/theme-rickiest.css';

import Nav from '../utilities/Header'
import Section from '../utilities/section'
import Header from '../utilities/Header'
import initObject from '../utilities/initObject';

function CareerHelper(props){
    return(
        <div className="row ">
            {/* <Nav/> */}
            <div className=" rowElement" >
                <div className="h_gallery_item">
                    <div className="work-left">
                        <div className="work-icon">
                            <img
                                alt=""
                                className="item-icon-base has-bursted"
                                src={require(`../style/${props.cr.src}`)}
                            />
                        </div>
                    </div>

                    <div className="work-right">
                        <h1 style={{ lineHeight: "1.1em" }} >
                            <span className="work-title" style={{color:"#fbfbfb" }} > {props.cr.title} </span>
                            <br></br>
                            <span style={{ fontSize: "80%", fontWeight: "500" }} >
                                <a style={{ color: "#c9d3e8", fontSize: "74%", fontWeight: "550" }} > {props.cr.name} </a>
                            </span>
                        </h1>
                        <span className="work-date" style={{color:"#fbfbfb" }} > {props.cr.time} </span>

                        <ul style={{marginRight:"5%",color:"#fbfbfb"}} >
                            {props.cr.desc.map((el, i) => {
                                return (
                                    <li key={i} style={{marginBottom:"10px"}}> {el} </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </div>

    )
}

class Career extends React.Component{
    constructor(props){
        super(props)
        initObject.homeVists = true;
        this.state = {
            careerFile: props.careerFile,
        }
    }
    render(){
        return (
            <Section wrapper={false} backgroundColor="rgb(69, 74, 88)">
                <Header name="Career"/>
                <div className="careerBody">

                    <div  className="w-100 mt-4">
                    {this.state.careerFile.career.map((el,i) => {
                        return (
                            <CareerHelper key={i} cr={el} />
                        )
                    })}
                    </div>

                </div>
            </Section>
        )
    }
}

export default Career