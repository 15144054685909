var DATA = {
    "meta":{
        "Hackathon" : [
            "Magenta",
            "Google",
            "Devpost",
            "UTM",
        ],
        
    },
    "Hackathons":[
        {
            "title":"MAESTRO",
            "img":"maestro.png",
            "labels": ['Magenta Hackathon'],
            "link":"https://github.com/mahikap/music-maestro",
            "needGit":true,
            "needNPM":false,
            "divItem":"python web NN JS all",
            "distribution":[
                ["JS",55],
                ["PYTHON",35],
                ["CSS",5],
                ["HTML",4]
            ],
            "desc":"Maestro"
        },
        
    ]
}

export default DATA

