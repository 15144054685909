import React from 'react';
import DATA from '../descriptions/project.js';

const Skills = () => {
    return (
        <div className="lettering">

            <div className="lang">
                <p className="text-left position-absolute "> Programming Languages:</p> {DATA.meta.lang.map((el, i) => {
                    return <p className="d-inline text-center label" key={i}>{el}</p>
                })}
            </div>
            <hr className="m-1"/>

            <div className="lang">
                <p className="text-left position-absolute "> Web Dev Tools:</p> {DATA.meta.web.map((el, i) => {
                return <p className="d-inline label" key={i}>{el}</p>
            })}
            </div>
            <hr className="m-1"/>

            {/*
            <div className="lang">
                    <p className="text-left position-absolute ">  OS: </p>{DATA.meta.OS.map((el, i) => {
                return <p className="d-inline label" key={i}>{el}</p>
            })}
            </div>
            <hr className="m-1"/> */}
            
            <div className="lang">
                        <p className="text-left position-absolute "> Database systems:</p> {DATA.meta.db.map((el, i) => {
                return <p className="d-inline label" key={i}>{el}</p>
            })}
            </div>
                       
        </div>
    );
}

export default Skills;