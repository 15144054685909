import React from 'react';
import '../style/pr.css';
import ProjectModal from './Modal';


function ProjectHelper(props) {
    const [show, setShow] = React.useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    return (
        <>
            <div className="img-responsive g_img_item" style={{ height: "100%", width: "100%" }} variant="primary" onClick={handleShow}>
                <div className="g_item_text ">
                    <div className="imgPic">
                        <div className="bar">
                            <p><b>{props.pr.title}</b></p>
                            <i></i>
                        </div>
                        <img
                            className="npm img_class" alt=""
                            src={require(`../static/thumbnail/${props.pr.img}`)} />
                    </div>
               </div>

               
                
                {/* <div className="g_item_text">
                    <div className="labelItems" style={{ width: "100%", height:"50px",display:"table" }}>

                        <div className="mt-2" style={{textAlign:"center", display:"table-cell", verticalAlign:"top"}} >
                            {props.pr.labels.map((el, i) =>
                                <p className="label mt-3" key={i} style={{ display: "inline-block" }}>{el}</p>)}
                        </div>
                    </div>
                </div> */}

                {/*
                <hr></hr>

               
                <section>
                    <div className="g_item_text m-3">
                    <div className="flex flex-50-gt-sm waypoint bars-wrap animated slide-in-right mt-2" data-animation="slide-in-right">
                        
                              {props.pr.distribution.map((el,i) =>{
                            return (
                                <div key={i} className="bar_load flex">
                                    <div className="bar_load fill" style={{ width: `${el[1]}%` }}>
                                        <div className="tag bold flex">{el[0]} - {el[1]}%</div>
                                    </div>
                                </div>
                            )
                        })} 

                    </div>
                    </div>
                </section> */}

            </div>
            
            <ProjectModal pr={props.pr} handleClose={handleClose}  show={show} />
        </>
    )

}

export default ProjectHelper