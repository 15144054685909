const careerFile = {
    "career":[
        // {
        //     "title":"Software Developer",
        //     "name": "AMD(Advance Micro Devices)",
        //     "time": "January 2019 — August 2019",
        //     "src":"amdLogo.png",
        //     "desc":[
        //         // "Helped developed from scratch, an new web application that is linked to a backend database(My SQL and MSSQL), developed on Django Web Framework used by the SW PMO team to manage the newly defined Software Phase Gate Process for SPG Team.",
        //         // "Assist a project manager in the management of code scanning requests to ensure timely resolution and remediation.This work requires scanning and parsing through Perforce code and determining if the code has been changed or removed, creation of JIRA tickets to track requests for change as a result of the analysis. ",
        //         // "Instituted processes and conducted functional testing of the Planisware PPM tool that helped the admin team verify end - user functionality / data roll - up.",
        //         // "Branched out to assist other teams in creating and assembling a functional prototype for the Software Program Management Operations team that allows multiple operations including Capital, Resource Management, Timesheet backup, Outside Services, Materials management, Travel budgets, and more to be hosted from a single launch point. ",
        //         // "Develop script to create an inventory of existing JIRA projects used to track SW tools that are under configuration control.This work contributes to the process of how change management project requests must conform to corporate AMD processes and definition of concise instructions for requesting new items. ",
        //         // "Investigate and document architectural modelling tool and capability.This work contributes to the standardization our architectural and notational guidelines for use across our organization.",
        //         "Developed, designed and deployed (CD) an internal distributed Project Portfolio Management web application using Django(Python) and MSSQL; this centralized the management of Software Phase Gate processes, increasing efficacy and communication within AMD.",
        //         "Generated weekly wire frames, and prototypes for the web application to inform the PMO team on the progress of the software.",
        //         "Use SCRUM for agile development and implemented all stages of the software development life cycle (SDLC) processes.",
        //         "Worked with the data architecture to ensure that all ongoing data migrations, de-duplication, and extraction are done swiftly.",
        //         "Created and maintained Software Architecture Documentation (SAD) and Software Design Documentation (SDD).",
        //         "Used GIT for collaboration and version control for the application."
        //     ]
        // }, 
        {
            "title": "Software Developer",
            "name": "Ceridian",
            "time": "August 2020 - Present",
            "src": "ceridian.png",
            "desc": [
                "Details Coming Soon",
            ]
        },
        {
            "title": "Software Infrastructure Lead",
            "name": "UTHT - University of Toronto Hyperloop Team",
            "time": "July 2020 - June 2022",
            "src": "utht.png",
            "desc": [
                "Led and mentored a diverse team of developers in the research, design, and development phases of these complex technical projects, fostering a culture of innovation and excellence within UTHT.",
                "Spearheaded the design and implementation of a real-time dashboard using AngularJS and other advanced frameworks, enhancing the visualization and monitoring of critical Hyperloop pod metrics such as speed and brake pressure",
                "Pioneered the development of a bespoke middleware library, leveraging WebSocket technology for seamless and instantaneous data transfer between the Hyperloop pod hardware and the AngularJS dashboard.",
                "Authored and deployed a specialized C++ library for the Odroid platform, integral for real-time operational management of the Hyperloop pod, ensuring efficient interfacing with all on-board hardware systems.",
                "Conceptualized and executed a sophisticated simulation environment, replicating the Hyperloop pod's functionality, enabling rigorous pre-deployment testing and status monitoring through the dashboard.",
                "Created and maintained Software Architecture Documentation (SAD) and Software Design Documentation (SDD)."
            ]
        },
        {
            "title": "Software Lead",
            "name": "IAI Lab - Intelligent Adaptive Interventions Lab",
            "time": "July 2020 - June 2022",
            "src": "IAI.png",
            "desc": [
                "Played a pivotal role as Lead Software Developer in advancing the IAI Lab's mission of transforming user interfaces into intelligent, adaptive systems, significantly contributing to the enhancement and personalization of user interventions.",
                "Led the software development lifecycle of MOOCLet software, implementing cutting-edge features and functionalities that align with the lab's vision of creating dynamic, user-centric applications.",
                "Championed Continuous Integration/Continuous Deployment (CI/CD) practices, ensuring streamlined, efficient, and error-free deployment of MOOCLet software, enhancing the lab's capacity for rapid iteration and responsiveness to user needs.",
                "Actively contributed to research and development discussions, bringing a software-centric perspective to the interdisciplinary team, aiding in the holistic development of intelligent, adaptive interventions."
            ]
        },
        {
            "title": "Software Developer",
            "name": "AMD(Advance Micro Devices)",
            "time": "May 2018 — August 2019 (16m)",
            "src": "amdLogo.png",
            "desc": [
                "Increased efficacy and communication within AMD and reduced time latency for program managers through developing, designing, and deploying an internal distributed OOP PPMT Web application made in Django(Python)",
                "Improved quartile resource allocation, management, and distribution by developing an MVC scalable R&D web application in PHP for the R&D team that allows easy resource manipulation by managers.",
                "Built a Java based smart wizard macro in Confluence for the SW Process Team to help and guide new incoming managers with their onboarding and process management.",
                "Improved big data processing and parsing  speed of raw project data from JIRA RESTful API by 23%  for the analytics team through creating scripts in Python and Perl for reporting and analytical purposes.",
                "Optimized process of verifying end-user functionality for the admin team by developing Testing scripts in JavaScript and Python for conducting functional testing of Planisware.",
                // "Increased the visual obstacle-tracking and traffic light detection by 18 % through developing a multi-threaded GPU-eccentric Neural Network model using Radeon VII GPU with the machine learning research team for autonomous vehicle demo.",
                "Established remote testing and coding development environment by using GIT for collaboration and version control.",
                "Created and maintained Software Architecture Documentation (SAD) and Software Design Documentation (SDD).",
            ]
        }, 
        {
            "title": "Teaching Assistant",
            "name": "University of Toronto",
            "time": "January 2018 — May 2020 (2yr 4m)",
            "src":"uoft.png",
            "desc": [
                "Taught data structures and algorithms in C and conveyed fundamental systems programming concepts, with key focus on the importance of efficiency and accuracy.",
                "Ran weekly tutorial sessions and guided students in their labs, with engaging teaching methods such as live coding."
            ]
        },
        {
            "title": "University of Toronto",
            "name": "Bachelor in Sciences, Computer Science Specialist",
            "time": "October 2015 — November 2020 (5yr)",
            "src":"uoft.png",
            "desc": [
                "Undergraduate in Computer Science Specialist at University of Toronto."
            ]
        }
    ]
}

export default careerFile