import React from 'react';
import ReactDOM from 'react-dom';
import {
    Lettering,
} from '../Home/Home'
import { AwesomeButton } from "react-awesome-button";
import '../style/theme-bojack.css';
import DATA from '../descriptions/hackathonFile';
import HackathonHelper from './HackathonHelper.js'
import Nav from '../utilities/Header'
import Skills from './Skills'
import initObject from '../utilities/initObject';
import Header from '../utilities/Header'
import $ from 'jquery';


class Hackathon extends React.Component{
    constructor(props){
        super(props)
        this.state={
            m:true,
            sel: ['Python', 'Javascript', 'React', 'Nerual Network'],
            nameClass:"all"
        }
        initObject.homeVists = true;
    }

    render(){
        return (
        <div  >
            <Header name="Hackathon"/>
            <div className="projectBody">
            <Skills/>
           
            <div className="container m-0 p-0" style={{width:"100%"}}>
                    <div className="row row_out">
                             {DATA.Hackathons.map((el,i)=>{
                                return(
                                    <div key={i} className={` gallery_product col-lg-3 col-md-5 col-sm-6 col-xs-4 filter ${el.divItem}`} id="python NN JS all">
                                        <div className="h_gallery_item projectItems">
                                            <HackathonHelper pr={el} />
                                        </div>

                                    </div>
                                )
                            })} 
                </div>
            </div>
            </div>
        </div>

    )
}
}

export default Hackathon;