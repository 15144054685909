import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import {
  Provider,
  withNavigationContext,
  withNavigationHandlers,
  Navigator
} from "./animations/src/index";

import Home from './component/Home/Home'
import Project from './component/Project/Project' 
import Career from './component/career/Career'
import Education from './component/Education/Education'
import DoorUI from './component/utilities/DoorUI'
import Hackathon from './component/Hackathons/Hackathon'
import Research from "./component/Research/Research";

import './index.css';
import './animations/styled/styles.scss'
import './animations/styled/fold-out-animation/fold-out-animation.scss';
import './animations/styled/cube-animation/cube-animation.scss';

import careerFile from './component/descriptions/careerFile';
import educationFile from './component/descriptions/educationFile';
import researchFile from "./component/descriptions/researchFile";


const NavigationSlider = withNavigationHandlers(Navigator);

const Slider = withNavigationContext(({ fullpage }) => {

  // const animation_style = "foldOutAnimation" 
  const animation_style = fullpage.navigation.slug === "" ? "cubeAnimation" : "foldOutAnimation";

  return (
    <NavigationSlider
      animation={animation_style}
      organicArrows={false}
          //transitionDelay={fullpage.navigation.slug === "" ? 00 : 0}
          //onTransitionRequest={() => {
          //    $('div.row.spacer').addClass("anime");
          //}}
      startupDelay={1315}
      startupScreen={
        <div style={{ backgroundColor:"#292c35"}}>
          <DoorUI />
        </div>
      }

      className= "awesome-slider"
      media={[
        {
          slug: "",
          className: "slide home",
          children:<Home />,
          
        },
        {
          slug: "projects",
          className: "slide projects",
          children: <Project/>
        },
        {
          slug: "career",
          className: "slide career",
          children: <Career careerFile={careerFile}/>,
        },
        {
          slug: "research",
          className: "slide research",
          children: <Research researchFile={researchFile}/>,
        },
        {
          slug: "education",
          className: "slide education",
          children: <Education />,
        },
        {
          slug: "hackathon",
          className: "slide projects",
          children: <Hackathon/>
        },
      ]
    }
      
    />
  )
})

class App extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      prop:this.props
    }
  }

  render(){
    const slug = window.location.pathname === "/" ? "" : window.location.pathname;
    return (
      <Router>
        <Switch>
          <Route exact path="" render={
            () => {
              return (
              <Provider slug={slug}>
                <Slider />
              </Provider>
              )
            }
          } />

        </Switch>
      </Router>
      
    )
  }
}


ReactDOM.render(
    <App />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

