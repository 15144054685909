
import React from 'react';
import '../style/lettering.css';
import {
    withNavigationContext
} from "../../animations/src/index";
import "../style/content.scss";
import Section from '../utilities/section';
import Header from '../utilities/Header'
import CubeScroll from '../utilities/cubeScroll';
import '../style/cubeScroll.css';
import $ from 'jquery';
import Tiles from '../Tiles/Tiles';
import '../style/Tiles.css';
import initObject from '../utilities/initObject.js';


class Home extends React.Component{

    constructor(props) {
        super(props)
        console.log(initObject.homeVists);
        this.touchScreenRotation = this.touchScreenRotation.bind(this);
    }

    componentDidMount() {

        
        // $('.quotes').scrollTop(468);  //use later
        //setInterval(function(){ n+=15;$('.quotes').scrollTop(n); if(n==900){alert("hello");return;} }, 1);
       
     

        let height = $('.quotes').height()

        $('.quotes').cubeScroll({
            perspective: 900,
            //shading: 'rgba(222,222,222)',
            margin: height
        });

        if (initObject.homeVists) {
            $('.quotes').scrollTop(window.outerHeight);
        }

        if (window.outerWidth < 400){
            this.touchScreenRotation();
        }


        //$('.awssld__content').backgroundColor("rgb(47, 47, 47)");
    }

    touchScreenRotation(){

        var i = $('.quotes').scrollTop()+30;
        const delay = ms => new Promise(res => setTimeout(res, ms));
        const yourFunction = async () => {
            
            while (i <  window.outerHeight){
                await delay(1);
                i+=10;
                $('.quotes').scrollTop(i);
            }
          };


        
        var ts;
        $(document).on('touchstart', function(e) {
            ts = e.originalEvent.touches[0].clientY;
        });
        
        var move = 0;
        $(document).on('touchmove', function(e) {
            var te = e.originalEvent.changedTouches[0].clientY;
            if (ts > te) {
                move ++;
                console.log('down');
            } else {
                console.log('up');
            }
        });

        $(document).on('touchend', function(e) {
            if (move > 2){
                yourFunction();
                console.log($('.quotes').scrollTop());
            }
            
        });
    }

    render() {
        return (
            <section className="quotes">
                <HomeHelper />
                <Tiles />
            </section>
        );
    }
}

class HomeHelper extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            textLettering: [
                "I am a graduate student from University Of Toronto, Specialized in Computer Science.",
                "I am currently working at Ceridian as a software Developer. I worked at AMD in RTG (Radeon Technology Group) as a rotational software developer intern."
            ]
        }
    }

    componentDidMount(){
        if (window.outerWidth > 400){
            this.setState({
                textLettering: [
                    "I am a graduate student from University Of Western Ontario, pursuing masters in Computer Science. I did my undergraduate degree from University of Toronto.",
                    "My research deals with finding solutions to Combinatorial Optimization Problems (COP) using physics based neural network. I am also currently working at Ceridian as a software Developer. I previously worked at AMD in RTG (Radeon Technology Group) as a rotational software developer intern.",
                    "My expertise lies in Scalable computation and graph theory. I am interesting in trying to find potential in intigerating ML with web dev, autonomous drones and quantum computing."
                ]
            })
        }
    }

    render() {

    return (
        <Section wrapper={false} backgroundColor="rgb(41 45 58)">
            
            <figure className="dial" style={{
                width: '300px',
                height: '300px',
                position: 'relative',
                marginLeft: "25%",
                marginBottom: "10%"
                }} >
                <div className="c"></div>
                <div className="c2"><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i></div>
                <div className="c3"></div>
                <div className="c4"></div>
                <div className="c5"></div>
                <div className="c6"></div>
            </figure>

            <Content
                main={
                    <Lettering
                        classname="Home"
                        title="Hello, my name is Mohammad Javaad Akhtar"
                        text={this.state.textLettering}
                    />
                }
              
            />

            <MouseScroll />
        </Section>
    );
};
}



export const Lettering = ({ text = [], title = '' , classname = ''}) => {
    const renderText = () => {
        return text && text.length
            ? text.map((line, index) => {
                return <div key={`${index}`} className={'sentence '  + classname}>{line}</div>;
            })
            : null;
    };

    return (
        <div className={'lettering ' + classname}>
            <h1>{title}</h1>
            {renderText()}
        </div>
    );
};



// const options = [
//     {
//         label: "Cube Animation",
//         value: "cubeAnimation"
//     },
//     {
//         label: "Fall Animation",
//         value: "fallAnimation"
//     },
//     {
//         label: "Fold Out Animation",
//         value: "foldOutAnimation"
//     },
//     {
//         label: "Open Animation",
//         value: "openAnimation"
//     },
//     {
//         label: "Scale Out Animation",
//         value: "scaleOutAnimation"
//     }
// ];

export const Content = withNavigationContext(({ fullpage, main, action }) => {
    return (
        <div className="content">
            <div className="content__main">{main}</div>
        </div>
    );
});

const MouseScroll = (props) =>{
    return (

            <div className="icon-wrapper">
                <div className="mouse-icon">
                    <div className="mouse"></div>
                    <span className="arrow arrow-01"></span>
                    <span className="arrow arrow-02"></span>
                    <span className="arrow arrow-03"></span>
                </div>
                <div className="hand-icon">
                    <div className="hand">
                        <div className="circle"></div>
                    </div>
                </div>
            </div>

    )
}

export const Select = ({ options = [], onChange, selected }) => {
    const renderOptions = () => {
        return options.map(({ value, label }) => {
            return (
                <option key={value} value={value}>
                    {label}
                </option>
            );
        });
    };

    return (
        <select
            value={selected}
            onChange={event => {
                onChange(event.currentTarget.value);
            }}
        >
            {renderOptions()}
        </select>
    );
};

export default Home;