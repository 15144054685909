import React from 'react';

const Maestro_html = (props) => {
    return (
        <div>
             <b>Maestro</b> is an application that uses the foundation of Magenta to explore how machine learning 
             can be used to teach music. 
             <hr></hr>
             <div>
                 Maestro is dedicated to helping users learn while providing instant 
                feedback on performance even in the absence of a teacher.
             </div> 
             <br></br>
             <div>
                Taking inspiration from sites such 
                as Khan Academy, Maestro provides video singing lessons followed by hands-on practice where students 
                try singing certain notes and arpeggios to see what they’ve learned. 
             </div>
        </div>
    );
}

export default Maestro_html;