import React from "react";
import {
    withNavigationContext,
    Link
} from "../../animations/src/index";

import "../style/nav.scss";

const BentoNav = (props) => {
    return (

                    <Link href="/" className="menu__item--bento">
                        <div className="container">
                            <div className="row">
                                <div className="MenuBox bigBox blue"></div>

                                <div className="container">
                                    <div className="row">
                                        <div className="MenuBox lime"></div>
                                        <div className="MenuBox purple"></div>
                                    </div>
                                    <div className="row">
                                        <div className="MenuBox long orange" ></div>
                                    </div>
                                </div>
                                
                            </div>
                            <div className="row">
                                <div className="MenuBox long orange"></div>
                                <div className="MenuBox lime"></div>
                                <div className="MenuBox redgay"></div>
                            </div>
                            {/* <div className="row">
                                <div className="MenuBox lime"></div>
                                <div className="MenuBox bluefish"></div>
                                <div className="MenuBox bluefish"></div>
                                <div className="MenuBox darkblue"></div>
                            </div> */}
                          </div>
                    </Link>     

    )
}

const Header = (props) => {
    var headerName = props.name || null;
    return(
        <header className="page-header">
        <div className="page-header__wrapper menu__wrapper">
            {/* <div className="page-header__title"> */}

            <Link href="/" className="page-header_name">
                        <div>
                            <NameHeader firstname="MOHAMMAD JAVAAD" lastname=" AKHTAR" />
                        </div>
                    </Link>
                <div style={{fontSize:'180%', fontStyle:'oblique', fontWeight:"bold", marginRight:'20%'}} className='header name'>{headerName}</div>

<BentoNav />


                {/* </div> */}
                </div></header>
    )
}

function NameHeader (props){
    return (
        <div style={{fontSize:'160%'}}>
            <span>{props.firstname}</span>
            <span>{props.lastname}</span>
        </div>
    )
}

const Nav = withNavigationContext(({ fullpage }) => {
    const { slug } = fullpage.navigation;

    return (
        <header className="page-header">
            <div className="page-header__wrapper">
                <div className="page-header__title">

                    <div>
                        <h1>
                            {slug === "" ? <NameHeader firstname="PORTFOLIO" lastname="PAGE"/>
                                : <NameHeader firstname="MOHAMMAD JAVAAD" lastname="AKHTAR" /> }
                        </h1>
                        
                    </div>
                </div>
                <nav>
                    <Link className={slug === "" ? "selected" : null} href="/">
                        index
                    </Link>
                    <Link
                        className={slug === "projects" ? "selected" : null}
                        href="/projects"
                    >
                        projects
                    </Link>
                    <Link
                        className={slug === "career" ? "selected" : null}
                        href="/career"
                    >
                        career
                    </Link>
                    <Link
                        className={slug === "research" ? "selected" : null}
                        href="/research"
                    >
                        research
                    </Link>
                </nav>
            </div>
        </header>
    );
});

export default Header;