import React from 'react';
import Modal from 'react-bootstrap/Modal'
import { AwesomeButton } from "react-awesome-button";
import ReactHtmlParser from 'react-html-parser'
import Maestro_html from '../descriptions/HTMLS/Maestro'


function GitProp(props){
    return (
    <div>
        <hr></hr>
        <div className='content__action__github'>
            <a  className="gitHubCont" 
                    target='_blank' rel="noopener noreferrer"
                    href={`${props.link}`}
            >
                <svg    className="gitHubCont" viewBox='0 0 16 16' version='1.1' aria-hidden='true'>
                    <path
                        fillRule='evenodd'
                        fill='#FFFFFF'
                        d='M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0016 8c0-4.42-3.58-8-8-8z'
                    />
                </svg>
                <span>Source on Github</span>
            </a>
        </div>
    </div>
    )
}

function NpmProp(props){
    return (
        <div>
            <div className='content__action__npm'>
                <a style={{
                    fontFamily:"Montserrat, tahoma, verdana, sans-serif",
                    color:"#c7c6c6",
                    display:"inline-block",
                    paddingLeft:"16px",
                    paddingRight:"10px",
                    fontWeight:"bold",
                    fontSize:"12px",
                    textDecoration:"none",
                    backgroundColor:"rgb(203, 56, 55)"
                }}
                    target='_blank' rel="noopener noreferrer"
                    href={`https://www.npmjs.com/package/${props.link}`}
                >
                    <span>Source on</span>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="62px" height="40px" viewBox="0 0 18 7">
                        <path fill="#CB3837" d="M0,0h18v6H9v1H5V6H0V0z M1,5h2V2h1v3h1V1H1V5z M6,1v5h2V5h2V1H6z M8,2h1v2H8V2z M11,1v4h2V2h1v3h1V2h1v3h1V1H11z" />
                        <polygon fill="#FFFFFF" points="1,5 3,5 3,2 4,2 4,5 5,5 5,1 1,1 " />
                        <path fill="#FFFFFF" d="M6,1v5h2V5h2V1H6z M9,4H8V2h1V4z" />
                        <polygon fill="#FFFFFF" points="11,1 11,5 13,5 13,2 14,2 14,5 15,5 15,2 16,2 16,5 17,5 17,1 " />
                    </svg>
                    
                </a>
            </div>
        </div>
   )
}

const HackathonModal = (props) => {
    var bodyDescription = require(`../descriptions/HTMLS/`+props.pr.desc).default();
    return (
        <Modal show={props.show} onHide={props.handleClose} centered style={{color:"black"}} >
        
        <Modal.Header>
            <Modal.Title>{props.pr.title}</Modal.Title>
        </Modal.Header>
        
        <Modal.Body>
        {bodyDescription}
            {props.pr.needGit ? <GitProp link={props.pr.link} />
            :
                props.pr.needNPM ? <NpmProp link={props.pr.link}/> : <div></div>
        }
        </Modal.Body>
        
        <Modal.Footer>
            <AwesomeButton type="secondary" action={props.handleClose}>Close</AwesomeButton>
        </Modal.Footer>
    
    </Modal>
    );
}

export default HackathonModal;