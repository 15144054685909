export class MediaLoader {
  constructor() {
    if (typeof window !== 'undefined') {
      this.image = new Image();
      this.resolve = null;
      this.video = document.createElement('video');
      this.events();
    }
  }
  events() {
    this.video.addEventListener(
      'loadeddata',
      () => this.resolve && this.resolve(true)
    );
    this.video.addEventListener(
      'loadeddata',
      () => this.resolve && this.resolve(false)
    );
    this.image.onload = () => this.resolve && this.resolve(true);
    this.image.onerror = () => this.resolve && this.resolve(false);
  }
  startLoad(url) {
    if (url.match(/\.(mp4|webm)/i)) {
      this.loadVideo(url);
    }
    if (url.match(/\.(png|jp(e)?g|gif|webp)/i)) {
      this.loadImage(url);
    }
  }
  loadMultiple(urls) {
    this.loaded = 0;
    this.toLoad = urls.length;
    return new Promise(resolve => {
      this.resolver = resolve;
      urls.forEach(url => {
        this.startLoad(url);
      });
    });
  }
}


export function getClassName(className = '', cssModule) {
  if (cssModule) {
    return cssModule[className] || className;
  }
  return className;
}
