import React from 'react';
import ReactDOM from 'react-dom';
import {
    Lettering,
} from '../Home/Home'
import { AwesomeButton } from "react-awesome-button";
import '../style/theme-bojack.css';
import DATA from '../descriptions/project.js';
import ProjectHelper from './ProjectHelper.js'
import Nav from '../utilities/Header'
import Skills from './Skills'
import initObject from '../utilities/initObject';
import Header from '../utilities/Header'
import $ from 'jquery';


class Project extends React.Component{
    constructor(props){
        super(props)
        this.state={
            m:true,
            sel: ['Python', 'Javascript', 'React', 'Nerual Network'],
            nameClass:"all"
        }
        initObject.homeVists = true;
        this.filterLabel = this.filterLabel.bind(this)
    }

    componentDidMount(){
        $('.projects').on('scroll', () => {
            if ($('.projects').scrollTop() > 43){
                console.log("scroll");
            }
            else{
                console.log("nonscroll");
            }
        })
    }

    filterLabel(e){
        let classname = e.classList
        this.setState({
            nameClass:classname[6]
        })
        let AllClasses = ReactDOM.findDOMNode(this).getElementsByClassName('filter')

        Object.entries(AllClasses).forEach(([key, value]) => {
            value.classList.contains(classname[6]) ? value.style.display = "contents" : value.style.display = "none"}
            )
        }

        

    render(){
        return (
        <div  >
            <Header name="Projects"/>
            <div className="projectBody">
            <Skills/>

            <Lettering 
                    classname="project"
                    // title="This is a project page"
                    text={[
                        <div>
                        <AwesomeButton className="ml-3 all" size="small" type={this.state.nameClass === "all"? "link" : "primary"} action={this.filterLabel}>All</AwesomeButton>
                        <AwesomeButton className="ml-3 python" size="small" type={this.state.nameClass === "python"? "link" : "primary"} action={this.filterLabel}>Python</AwesomeButton>
                        <AwesomeButton className="ml-3 web" size="medium" type={this.state.nameClass === "web"? "link" : "primary"} action={this.filterLabel}>Web Dev</AwesomeButton>
                        <AwesomeButton className="ml-3 C" size="small" type={this.state.nameClass === "C"? "link" : "primary"} action={this.filterLabel}>C</AwesomeButton>
                        <AwesomeButton className="ml-3 JS" size="medium" type={this.state.nameClass === "JS"? "link" : "primary"} action={this.filterLabel}>JavaScript</AwesomeButton>
                        <AwesomeButton className="ml-3 NN" size="large" type={this.state.nameClass === "NN"? "link" : "primary"} action={this.filterLabel}>Neural Network</AwesomeButton>
                        </div>
                    ]}
            />
           
            <div className="container m-0 p-0" style={{width:"100%"}}>
                    <div className="row row_out">
                             {DATA.project.map((el,i)=>{
                                return(
                                    <div key={i} className={` gallery_product col-lg-3 col-md-5 col-sm-6 col-xs-4 filter ${el.divItem}`} id="python NN JS all">
                                        <div className="h_gallery_item projectItems">
                                            <ProjectHelper pr={el} />
                                        </div>

                                    </div>
                                )
                            })} 
                </div>
            </div>
            </div>
        </div>

    )
}
}

export default Project