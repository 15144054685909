var DATA = {
    "meta":{
        "lang" : [
            "Python",
            "Java",
            "C",
            "PHP",
            "Javascript",
            "Groovy",
            "Typescript",
            "C++",
            "Ruby"
        ],
        "web" :[
            "HTML",
            "CSS",
            "DJango",
            "ReactJS",
            "VueJS",
            "Ruby on Rails",
            "SCSS",
            "Boostrap"
        ],
        "OS":[
            "Windows",
            "Linux",
            "RedHat",
            "OSX"
        ],
        "db":[
            "MySQL",
            "PostgreSQL",
            "NoSQL",
            "MongoDB"
        ]
    },
    "project":[
        {
            "title":"POSITWEET",
            "img":"pt.png",
            "labels": ['PYTHON (DJANGO)', 'REACTJS','NEURAL NETWORK'],
            "link":"Positweets",
            "needGit":true,
            "needNPM":false,
            "divItem":"python web NN JS all",
            "distribution":[
                ["JS",55],
                ["PYTHON",35],
                ["CSS",5],
                ["HTML",4]
            ],
            "desc":"<div>A <b>full-stack web application</b> integrated with Twitter API to identify sentiments of tweets and allow users to post their blogs inside the applications (independent from twitter).</div> <hr></hr> <div> This is a <b>full-stack web application</b> created with the purpose of making social media more positive and optimistic. This project has two primary focuses: <ul> <li>Can we use sentiment analysis to filter out negative content using an LSTM? More detail regarding LSTM and sentiment analysis API can be found <a href='https://github.com/MJavaadAkhtar/sentiment-analysis-LSTM/blob/master/README.md'>here</a>.</li> <li>Can we use generative autoencoders to create positive contents? More details regarding the autoencoders coming soon.</li> </ul> </div>"
        },
        {
            "title": "AUTO-ENCODER BOTS",
            "img": "auto.gif",
            "labels": ['PYTHON','NEURAL NETWORK'],
            "link": "photo-snapper",
            "needGit": false,
            "needNPM": false,
            "divItem": "python NN all",
            "distribution": [
                ["PYTHON", 95],
                ["HTML", 5]
            ],
            "desc": "<div>A <b>GRU Autoencoder bots</b> that allows users to create an abstract tweet based on user sentiment requirements. The project is developed with the aim to find if an GRU autoencoder can mimic human behaviour.</div><hr></hr><ul> <li>Developed an Gated recurrent units (GRU) based autoencoder that created tweets.</li><li>Integerated with sentiment analysis API allowing user to create tweets based on required sentiment.</li><li>Encoder can take a sequence of inputs to create a low-level embedding</li><li>Decoder is a combination of recurrent Neural Network (using GRU) and Multi-layer perceptron to create a logical sentence.</li></ul><hr></hr>"
        },
        {
            "title": "PHOTO SNAPPER",
            "img": "npm.png",
            "labels": ['JAVASCRIPT'],
            "link":"photo-snapper",
            "needGit":false,
            "needNPM":true,
            "divItem": "JS web all",
            "distribution":[
                ["JS",97],
                ["HTML",2],
                ["CSS",1]
            ],
            "desc": "<div>A <b>npm package</b> that allows users to take photos directly from the web and save it on their computers.</div> <hr></hr> <h4>Overview</h4> <div> An NPM package that utilizes the power of WebRTC to allow users to take photo from web browser. <ul> <li>Created in pure javascript, without use of any external library.</li> <li>Uses <b>self-built adapter</b> software design pattern to create a ployfill allowing user to even use the application on the browser that don't support WebRTC.</li> <li>Created different filters, which allows users to apply convolution on the image directly.</li> </ul> </div> <hr></hr> <h4>Feature</h4> <div> <ul> <li>User have the ability to switch camera front face to back face if the device has more than one camera.</li> <li>Gives users the ability to upload there own filter matrix to apply it to their picture.</li> <li>Provides ability to save picture on their computer directly.</li> <li>Made very easy to setup and use the npm package.</li> </ul> </div> <hr></hr>"
        },
        {
            "title": "CONFERENCE APP",
            "img": "ftp.gif",
            "labels": ['C'],
            "link":"Text-Conferencing-app",
            "needGit":true,
            "needNPM":false,
            "divItem": "C web all",
            "distribution":[
                ["C",100],
            ],
            "desc":" <div> <div>A Text Conferencing application integerated with my own implementation of FT and SMTP</div> <ul> <li>The application is created using the <b>C langauge</b>.</li> <li>It utilizes Sockets for packet transportation.</li> <li>Uses 3-way handshake of TCP connection to create a connection between two computer and UDP model to transfer file between computers.</li> <li>Created our own custom <b>Round-Trip delay time (RTT)</b> which the TCP uses to estimate timeout.</li> </ul> <hr></hr> <h4>Features:</h4> <ul> <li>Allows users to create account and login.</li> <li>Users are able to create their own conferencing sessions or can join an already existing session.</li> <li>Users can invite other valid users to their conferencing sessions via ping.</li> <li>Gives the ability to the users to run bash terminal commands inside their conference sessions.</li> <li>Allows users to send files within the conference session using custom made FTP.</li> </ul> </div>"
        },
        {
            "title": "SQL and Matrix Parallelizer",
            "img": "MM.gif",
            "labels": ['CUDA','C++'],
            "link":"",
            "needGit":false,
            "needNPM":false,
            "divItem": "C all",
            "distribution":[
                ["C++",67],
                ["CUDA",33]
            ],
            "desc":" <div> A Query Language optimizer by treating datasets as matrices and used the power of Parallelization in CPU and dGPU to make it faster. <ul> <li>Built our own sequential SQL Nested loop, Merge Join and Hash Join operators to set base run time which will be used for comparisons later.</li> <li>Created Joines using concepts of <b>pthreads</b> and thread synchronisations in an attempt to make join operations faster.</li> <li>Used <b>OpenMP</b> library and <b>Message Parser Interface(MPI)</b> to optimize our joins operators in order to make the join faster.</li> <li>Used <b>CUDA</b> to increase effeciency of joins by dividing the datasets within the GPU threads, treating them as smaller matrices and each thread applying matrix multiplication.</li> </ul> </div>"
        },
        {
            "title": "PROTEUS",
            "img": "prot.gif",
            "labels": ['PYTHON (DJANGO)'],
            "link":"",
            "needGit":false,
            "needNPM":false,
            "divItem": "python web JS all",
            "distribution":[
                ["PYTHON",61],
                ["JS",23],
                ["HTML",12],
                ["CSS",4]
            ],
            "desc":"<div> A Project Management Web Application for Projects Managers at AMD to help them track their Project Status and Gate Decisions. <ul> <li>Used <b>Django web framework</b> for back-end development and logistic handler for the application.</li> <li>Used Algorithm and heuristics to make searches faster inside the program.</li> <li>Using various type of web development tools (mostly <b>HTML,CSS,Javascript,nodejs and Jquery</b>) in order to make the website User Interface more dynamic and easier to use.</li> <li>Used <b>MSSQL</b> and <b>MySQL</b> to store the status of artifact and gates, and alert the PM if a project is at risk.</li> <li>Used <b>GIT</b> as control version for project and deployed it on Redhat server.</li> </ul> </div>"
        },
        {
            "title": "WAREHOUSE WAR GAME",
            "img": "warehouse.gif",
            "labels": ['JAVASCRIPT','NODEJS','REACTJS','MONGO DB'],
            "link":"Warehouse-War-Game",
            "needGit":true,
            "needNPM":false,
            "divItem": "JS web all",
            "distribution":[
                ["JS",93],
                ["CSS",5],
                ["HTML",2]
            ],
            "desc":"<div> <h4>Overview:</h4> <div>This is an web based multiplayer game with the goal to kill off as many monsters as possible.</div> <ul> <li>The game front end is mostly comprised of ReactJs.</li> <li>Bootstrap and CSS is used for beautification.</li> <li>Backend is comprised of purely Nodejs and RestAPI.</li> <li>Sqlite3 is used for database.</li> <li>Different algorithms are used to move the monsters, including Ford-Fulkersein Method to find least cost to kill a player and AI algorithm called A* Search with heuristics to find fastest way to kill multiple players.</li> </ul> <hr></hr> <h4>Features:</h4> <ul> <li>Have a fully functioning login system and allows new players to register.</li> <li>Allows multiple people to join the same game.</li> <li>Keep history of scores for each players and displays the top 3 scores.</li> <li>The game contains lobby where players are allowed to choose their avatars.</li> <li>Allows players to message anyone in the lobby of the game.</li> <li>Contains Monsters that should be kill. Also includes a smart monster which uses A* Search to locate players and try to kill them.</li> </ul> </div>"
        },
        {
            "title": "CLOTH IDENTIFIER CNN",
            "img": "cloth.gif",
            "labels": ['PYTHON','MATLAB'],
            "link":"Cloth-Identifier-CNN",
            "needGit":true,
            "needNPM":false,
            "divItem": "python NN all",
            "distribution":[
                ["MATLAB",37],
                ["CUDA",32],
                ["C++",9],
                ["OTHERS",22]
            ],
            "desc":"<div> A Convolutional Neural Network in Python and Matlab which identifies the type of cloths a User is wearing. <ul> <li>Trained the CNN rigorously on a large data set of cloths and style.</li> <li>Created the CNN model mimicing <b>AlexNet</b> connected with softmax acivation with cross entropy for multi-class classification.</li> <li>used Back Propagation in CNN to make CNN more accurate, effecient and faster.</li> </ul> </div>"
        }
    ]
}

export default DATA

