import React from 'react';
import '../style/pr.css';
import HackathonModal from './Modal';


function HackathonHelper(props) {
    const [show, setShow] = React.useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    return (
        <>
            <div className="img-responsive g_img_item" style={{ height: "100%", width: "100%" }} variant="primary" onClick={handleShow}>
                <div className="g_item_text ">
                    <div className="imgPic">
                        <div className="bar">
                            <p><b>{props.pr.title}</b></p>
                            <i></i>
                        </div>
                        <img
                            className="npm img_class" alt=""
                            src={require(`../static/hacks/${props.pr.img}`)} />
                    </div>
               </div>

               {/* <div className="g_item_text">
                    <div className="labelItems" style={{ width: "100%", height:"50px",display:"table" }}>

                        <div className="mt-2" style={{textAlign:"center", display:"table-cell", verticalAlign:"top"}} >
                            {props.pr.labels.map((el, i) =>
                                <p className="label mt-3" key={i} style={{ display: "inline-block" }}>{el}</p>)}
                        </div>
                    </div>
                </div> */}

            </div>
            
            <HackathonModal pr={props.pr} handleClose={handleClose}  show={show} />
        </>
    )

}

export default HackathonHelper;