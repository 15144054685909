const educationFile = {
    "career":[
        {
            "title": "University of Western Ontario",
            "name": "Masters in Sciences, Computer Science Specialist",
            "time": "September 2022 — Present",
            "src":"uwo.png",
            "desc": [
                "Pursuing master degree in computer science at University of Western Ontario."
            ]
        },
        {
            "title": "University of Toronto",
            "name": "Bachelor in Sciences, Computer Science Specialist",
            "time": "October 2015 — November 2020 (5yr)",
            "src":"uoft.png",
            "desc": [
                "Completed Undergraduate in Computer Science Specialist at University of Toronto."
            ]
        }
    ]
}

export default educationFile