import React from 'react';
import Section from '../utilities/section';
// import {
//     withNavigationContext,
//     Link
// } from "react-awesome-slider/dist/navigation";

import {
	Link
} from '../../animations/src/index'

import {
	Link as ReactLink
} from "react-router-dom";

import $ from 'jquery';
import '../style/LiveTile.scss'
import { faHome,faPenAlt, faEnvelope, faTerminal, faFile,
	faGraduationCap, faCode, faBriefcase,
	faCogs } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faLinkedin,
	faLinkedinIn,
	faGithub,
	faTwitterSquare,
	faTwitter
}
	from '@fortawesome/free-brands-svg-icons'
import Resume from '../static/resume/resume.pdf'


class Tiles extends React.Component {
	render() {
		return (
			<Section wrapper={false} backgroundColor="rgb(41 45 58)">
				<div className="container_tiles">

					{ window.innerWidth <= 550?
						<div className="container">
							<div className='row' style={{width:'270px', margin:'auto'}}>

								<SingleTile size='box' colors="blue" text="Projects" icons={faCogs} link="/projects" />

								<SingleTile size='box' colors="purple" text="Education" icons={faGraduationCap} link="/education" />

								<SingleTile size='box' colors="orange" text="Career" icons={faBriefcase} link="/career" />

								<SingleTile size='box' colors="orange" text="Blogs" icons={faPenAlt} link="/research" clickable={true}/>

								<SingleTile size='box' colors="black" text="Github"
											icons={faGithub}
											link="https://github.com/MJavaadAkhtar"
											reactLink = {true}/>

								<SingleTile size='box' colors="redgay" text="Resume" icons={faFile} link={Resume} reactLink = {true} />

								<SingleTile size='box' colors="bluefish" text="Twitter"
											icons={faTwitter}
											link="https://twitter.com/AkhtarJavaad"
											reactLink = {true}/>

								<SingleTile size='box' colors="darkBlue" text="LinkedIn"
											icons={faLinkedinIn}
											link="https://www.linkedin.com/in/mohammad-javaad-akhtar-a0aa97115"
											reactLink = {true}/>

							</div>
						</div>:

						<div className='container' style={{width:'540px'}}>
							<div className="row">
								<SingleTile size='bigBox' colors="blue"
											text="Projects" icons="" link="/projects" live={true}
											sideImg={{
												side1: {
													name: "Positweet",
													imgSrc: "pt.png"
												},
												side2: {
													name: "Auto-Encoder Bot",
													imgSrc: "auto.png"
												},
												side3: {
													name: "Cloth Identifier CNN",
													imgSrc: "cloth.png"
												},
												side4: {
													name: "Conference App",
													imgSrc: "ftp.png"
												},
												type:"cube"
											}}
								/>

								<div className="container" style={{width:'268px'}}>
									<div className="row">

										<SingleTile size='box' colors="black" text="Hackathon" icons={faCode} link="/hackathon" />

										<SingleTile size='box' colors="purple" text="Education" icons={faGraduationCap} link="/education" />

									</div>
									<div className="row" style={{paddingTop:"5px"}}>

										<SingleTile size='wide' colors="orange"
													text="Career" icons="" link="/career" live={true}
													sideImg={{
														side1: {
															name: "UofT",
															position: "Teaching Assistant",
															imgSrc:"uoft.png"
														},
														side2: {
															name: "AMD",
															position:"Software Developer",
															imgSrc:"amdLogo.png"
														},
														side3: {
															name: "UTHT",
															position: "Software Infrastructure Lead",
															imgSrc:"utht.png"
														},
														side4: {
															name: "Ceridian",
															position:"Software Developer",
															imgSrc:"ceridian.png"
														},
														type:"rectangle"
													}}
										/>
									</div>
								</div>
							</div>

							<div className="row" >

								<SingleTile size='wide' colors="lime"
											text="Research" icons="" link="/research" live={true}
											sideImg={{
												side1: {
													name: "UWO",
													position: "Principle Researcher",
													imgSrc:"COP.png"
												},
												side2: {
													name: "UWO",
													position:"Directed Research Project",
													imgSrc:"DRP.png"
												},
												side3: {
													name: "UTHT",
													position: "Software Infrastructure Lead",
													imgSrc:"utht.png"
												},
												side4: {
													name: "IAI Lab",
													position:"Software Lead",
													imgSrc:"IAI.png"
												},
												type:"rectangle"
											}}
								/>

								<SingleTile size='box' colors="black" text="Terminal"
											icons={faTerminal}
											link=""
											clickable={false}/>

								<SingleTile size='box' colors="redgay" text="Resume" icons={faFile} link={Resume} reactLink = {true}/>

							</div>
							<div className="row">

								<SingleTile size='box' colors="black" text="Github"
											icons={faGithub}
											link="https://github.com/MJavaadAkhtar"
											reactLink = {true}/>

								<SingleTile size='box' colors="darkBlue" text="Email" icons={faEnvelope}
											link="mailto:mj[dot]akhtar[at]outlook[dot]com" reactLink = {true}/>

								<SingleTile size='box' colors="bluefish" text="Twitter"
											icons={faTwitter}
											link="https://twitter.com/AkhtarJavaad"
											reactLink = {true}/>

								<SingleTile size='box' colors="darkBlue" text="LinkedIn"
											icons={faLinkedinIn}
											link="https://www.linkedin.com/in/mohammad-javaad-akhtar-a0aa97115"
											reactLink = {true}/>

							</div>
						</div>
					}
				</div>
			</Section>
		)
	}
}

class SingleTile extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			size: props.size,
			colors: props.colors,
			text: props.text,
			icon: props.icons,
			link: props.link,
			live: props.live,
			interval: 0,
			sideImg: props.sideImg || null,
			reactLink: props.reactLink || false,
			clickable: props.clickable != null ? props.clickable: true,
			rotatingCubbeAnimation:null,
		}
	}

	componentDidMount() {

		var intervals = 0;
		var intervals2 = 0;
		let tickDivider = 0;
		var degList = [0,90,180,270,180,90]
		$('.LiveTile.bigBox').css('transform', 'translateZ(-127px) rotateX(90deg)');
		$('.LiveTile.wide').css('transform', 'translateZ(-62px) rotateX(90deg)');

		this.setState({
			rotatingCubbeAnimation : setInterval(() => {
				intervals++;
				tickDivider+=1000;
				let indexDeg = intervals%6;
				$('.LiveTile.wide').css('transform', 'translateZ(-62px) rotateX(' + degList[indexDeg] + 'deg)');
				if(tickDivider%2000 == 0){
					intervals2++;
					$('.LiveTile.bigBox').css('transform', 'translateZ(-127px) rotateX(' + degList[intervals2%6] + 'deg)');
				}

			}, 2000, intervals, intervals2,degList,tickDivider)
		})
	}

	componentWillUnmount(){
		clearInterval(this.state.rotatingCubbeAnimation)
		console.log('unmounting');

	}

	render() {

		var LiveStatus = this.state.live
		return (
			<div className={'Tiles '+this.state.text}>
				{LiveStatus ?
					<div className={"LiveTilesWrapper " + this.state.size} >

						<Link href={this.state.link} className={'hoverElement'} >
						</Link>


						<LiveTiles state={this.state} />
					</div>
					:

					this.state.reactLink ?
						<a href={this.state.link}
						   className={this.state.size + ' ' + this.state.colors+ ' '+ this.state.clickable}
						   target="_blank"
						>
							<div className="hoverElement">

							</div>
							<div className="fontAwesome">
								<FontAwesomeIcon style={{ margin: '30px' }}
												 icon={this.state.icon} size="3x" color="white"
								/>
							</div>

							<h2>{this.state.text}</h2>
						</a>
						:

						<Link href={this.state.link}
							  className={this.state.size + ' ' + this.state.colors + ' '+ this.state.clickable}
							  target="_blank"
						>
							{this.state.clickable?
								<div className="hoverElement"></div> :
								<div></div>}

							<div className="fontAwesome">
								<FontAwesomeIcon style={{ margin: '30px' }}
												 icon={this.state.icon} size="3x" color="white"
								/>
							</div>

							<h2>{this.state.text === 'Blogs'? this.state.text+ ' - Coming Soon': this.state.text}</h2>
						</Link>
				}



			</div>


		)
	}
}

const LiveTiles = (props) => {

	const size = props.state.size;
	const cubeFaces = props.state.sideImg;

	return (
		<div className={"LiveTile " + size}>

			<Link href={props.state.link} className={size + ' liveCube-face faceFront ' + props.state.colors}>
				{cubeFaces == null ?
					<i className={props.state.icon}></i> :
					<CubeFace state = {props.state.sideImg.side1} type = {props.state.sideImg.type}/>
				}
				<h2>{props.state.text}</h2>
			</Link>

			<Link href={props.state.link} className={size + ' liveCube-face faceTop ' + props.state.colors}>
				{cubeFaces == null ?
					<i className="icon-camera"></i> :
					<CubeFace state = {props.state.sideImg.side2} type = {props.state.sideImg.type}/>
				}
				<h2>{props.state.text}</h2>
			</Link>

			<Link href={props.state.link} className={size + ' liveCube-face faceBack ' + props.state.colors}>
				{cubeFaces == null ?
					<i className="icon-camera"></i> :
					<CubeFace state = {props.state.sideImg.side3} type = {props.state.sideImg.type}/>
				}
				<h2>{props.state.text}</h2>
			</Link>

			<Link href={props.state.link} className={size + ' liveCube-face faceBottom ' + props.state.colors}>
				{cubeFaces == null ?
					<i className="icon-camera"></i> :
					<CubeFace state={props.state.sideImg.side4} type={ props.state.sideImg.type}/>
				}
				<h2>{props.state.text}</h2>
			</Link>


			<Link href={props.state.link} className={size + 'liveCube-face faceSideLeft ' + props.state.colors}>

			</Link>

			<Link href={props.state.link} className={size + 'liveCube-face faceSideRight ' + props.state.colors}>

			</Link>

		</div>
	)
}

const CubeFace = (props) => {

	const imgs = props.state;
	const type = props.type;
	//console.log(imgs.imgSrc);

	return (

		<div>
			{
				type == "rectangle" ?
					<div className="imgDiv">

						<img
							alt=""
							className=""
							style={{ height: '53px', width: '53px', margin: '8px' }}
							src={require(`../style/${imgs.imgSrc}`)}
						/>


						<div className="CareerName"
							 style={{ display: "inline-grid", position: "absolute", marginTop: "10px" }}
						>
							<p style={{
								margin: '0px',
								fontFamily: "Segoe UI,Frutiger,Frutiger Linotype,Dejavu Sans,Helvetica Neue,Arial,sans-serif",
								fontWeight: 400,
							}}> {imgs.name}</p>

							<p className="careerPost"
							   style={{
								   margin: '0px',
								   fontFamily: "Segoe UI,Frutiger,Frutiger Linotype,Dejavu Sans,Helvetica Neue,Arial,sans-serif",
								   fontWeight: 400,
								   fontSize: '14px',
							   }}> {imgs.position}</p>
						</div>


					</div>
					:
					<div className="imgDiv">

						<img
							alt=""
							className=""
							style={{ height: '163px', width: '163px', margin: '10px 5px 2px 48.5px' }}
							src={require(`../style/${imgs.imgSrc}`)}
						/>


						<div className="CareerName"
							 style={{  marginTop: "10px" }}
						>
							<p	className="projectPost"
								  style={{
									  margin: '0px',
									  fontFamily: "Segoe UI,Frutiger,Frutiger Linotype,Dejavu Sans,Helvetica Neue,Arial,sans-serif",
									  fontWeight: 400,
									  textAlign:"center"
								  }}>
								{imgs.name}</p>
						</div>


					</div>

			}
		</div>
	);
}
export default Tiles;