import React from 'react';
import DATA from '../descriptions/hackathonFile.js';

const Skills = () => {
    return (
        <div className="lettering">

            <div className="lang">
                <p className="text-left position-absolute "> Hackathons:</p> {DATA.meta.Hackathon.map((el, i) => {
                    return <p className="d-inline text-center label" key={i}>{el}</p>
                })}
            </div>
            <hr className="m-1"/>
                       
        </div>
    );
}

export default Skills;